import styled from "styled-components";
import ArrowDown from "../../../images/arrow-down.svg";
import {WrapperConfigFirst} from "../FirstConfig/styles";
import SecondConfig from "./SecondConfig";
import {Field} from "formik";

const breakpoint1 = '1400px'
const breakpoint2 = '900px'

export const WrapperConfigSecond = styled.div`
  max-width: 1110px;
  background: white;
  position: relative;
  margin: 30px auto;
  border-radius: 20px;
  color: #333333;
  padding: 16px;

  h1 {
    text-align: center;
  }

  @media (min-width: 1200px) {
    padding: 60px 100px;
  }
`
export const FeaturesListConfig = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  align-items: baseline;
  margin-top: 60px;
  @media (max-width: ${breakpoint2}) {
    max-width: 100%;
    flex-direction: column;
    align-items: self-start;
  }

`
export const FeatureConfig = styled.li`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
  white-space: nowrap;
  min-width: 40%;
  justify-content: space-between;
  display: flex;
  align-items: baseline;
  align-self: flex-start;
  @media (max-width: ${breakpoint2}) {
    flex-direction: column;
    gap: 8px;
  }

`
export const BlueSquareConfig = styled.div`
  min-width: 10px;
  min-height: 10px;
  max-width: 10px;
  max-height: 10px;
  background-color: ${props => props?.theme?.colors?.blue};
  margin-right: 14px;
  margin-top: 4px;
  display: inline-block;
`
export const SelectLabelConfig = styled(Field)`
  border: 1px solid ${props => props.theme.colors.lightGray + '35'};
  border-radius: 20px;
  width: 83px;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  background: url(${ArrowDown}) no-repeat right #faf7f5;
  -webkit-appearance: none;
  background-position-x: 60px;
  color: ${props => props.theme.colors.lightGray + '80'};
  font-weight: 700;
`
export const InputLabelConfig = styled(Field)`
  padding: 8px 0;
  max-width: 100%;
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.colors.lightGray + '80'};
  font-weight: 700;
  border: 0;
  background: transparent;
  outline: 0;
  
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputLabelWrapper = styled.div`
  background: #faf7f5;
  border: 1px solid ${props => props.theme.colors.lightGray + '35'};
  border-radius: 20px;
  padding: 0 30px 0 15px;
  width: 83px;
  
  span{
    font-size: 14px;
    small{
      font-size: 8px;
          vertical-align: text-top;
    }
  }
`

export const WrapperInputItemRadioConfig = styled.div`
  label {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;
    color: #70707080;
    cursor: pointer;
  }
`

export const WrapperInputItemsRadioConfig = styled.div`


  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: white;

    margin: 0;

    font: inherit;
    color: currentColor;
    width: 14px;
    height: 14px;
    border: 1px solid #70707080;

    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;

    background-color: #EF512C;
  }

  input[type="radio"]:checked + span {
    color: #333333;
  }

  input[type="radio"]:checked {
    box-shadow: 2px 3px 6px #70707080;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
`

export const FeaturesListColumn = styled.div`
  min-width: 40%;
`
