import styled from "styled-components";
import {WrapperConfigSecond} from "../SecondConfig/styles";
import Switch, {SwitchText} from "../../../components/Switch/Switch";

export const WrapperConfigFourthMail = styled(WrapperConfigSecond)`
  background: #0138AC;
  box-shadow: 50px 43px 46px #2E09007D;
  border: 8px solid #EDE4D3;
  border-radius: 20px;
  color: #EDE4D3;
  width: 100%;
  position: absolute;
  bottom: -400px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1200px) {
    position: absolute;
    bottom: -300px;
  }
`
export const WrapperContentFourthMail = styled.div`
  max-width: 497px;
  margin: auto;
  text-align: center;
  
    ${SwitchText}{
      color: #fff;
    }
`
export const WrapperInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

`
export const WrapperAction = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`