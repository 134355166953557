import React from 'react'
import LabelCart from "../../../components/LabelCart/LabelCart";

import {
    SelectLabelConfig,
    WrapperConfigSecond,
    FeaturesListConfig,
    FeatureConfig,
    BlueSquareConfig,
    InputLabelConfig, WrapperInputItemsRadioConfig, WrapperInputItemRadioConfig, FeaturesListColumn, InputLabelWrapper
} from "./styles";
import HeaderCart from "../../../components/HeaderCart/HeaderCart";
import {Field} from "formik";
import {Error} from "../../../components/Error/Error";

const SecondConfig = () => {

    return <>
        <WrapperConfigSecond>
            <LabelCart number={'2.'} colorLabel={'#01804D'} secondColor={'#0138AC'}/>
            <HeaderCart>Określ podstawowe parametry</HeaderCart>
            <FeaturesListConfig>
                <FeaturesListColumn>
                    <FeatureConfig>
                        <div>
                            <BlueSquareConfig/>Powierzchnia:
                        </div>
                        <InputLabelWrapper>
                            <InputLabelConfig name={"surface"} type={"text"} length={3} maxLength={3} placeholder={"000"} />
                            <span>m<small>2</small></span>
                        </InputLabelWrapper>
                        <Error name={"surface"} component="div" />
                    </FeatureConfig>
                    <FeatureConfig>
                        <div>
                            <BlueSquareConfig/>Ilość pomieszczeń:
                        </div>
                        <SelectLabelConfig as={"select"} name={"rooms"}>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                        </SelectLabelConfig>
                        <Error name={"rooms"} component="div" />
                    </FeatureConfig>
                </FeaturesListColumn>
                <FeaturesListColumn>
                    <FeatureConfig>
                        <div><BlueSquareConfig/>Rodzaj budynku:</div>
                        <WrapperInputItemsRadioConfig>
                            <WrapperInputItemRadioConfig>
                                <label> <Field type="radio"  value={"0"} name="building_type"/> <span> Dom </span></label>
                            </WrapperInputItemRadioConfig>
                            <WrapperInputItemRadioConfig>
                                <label> <Field type="radio" name="building_type" value={"1"} /> <span>Szeregówka /
                               Bliźniak</span> </label>
                            </WrapperInputItemRadioConfig>
                            <WrapperInputItemRadioConfig>
                                <label> <Field type="radio" name="building_type" value={"2"} />
                                    <span>Mieszkanie</span></label>
                            </WrapperInputItemRadioConfig>
                        </WrapperInputItemsRadioConfig>
                    </FeatureConfig>
                </FeaturesListColumn>
            </FeaturesListConfig>
        </WrapperConfigSecond>
    </>
}
export default SecondConfig