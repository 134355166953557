import styled from "styled-components";

export const StyledLoader = styled.div`
  position:fixed;
  left:0;
  right:0;
  bottom:0;
  top:0;
  z-index: 9899;
  background: ${ props => props.theme.colors.blue }70;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderText = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color:#fff;
`;
