import styled from "styled-components";
import bgc from "../../../images/front-background-config.jpg";

export const StyledBanner = styled.div`
  min-height: calc(100vh + 170px);
  
  background: linear-gradient(
          to bottom,
          rgba(0,0,0, 0),
          rgba(0,0,0, 90%)
  ),url(${bgc});
  
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding-top: 120px;
  @media (min-width: 1200px) {
    padding-top: 0px;
    padding-bottom: 263px;
}
  
`
export const WrapperContetnBanner = styled.div`
  max-width: 731px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: #333333;
  p {
    margin-bottom: 20px;
  }
  h1 {
    margin-bottom: 20px;
  }
`
export const StartConfigTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
`