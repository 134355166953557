import React, {useState} from 'react'
import Switch from "../Switch/Switch";
import {WrapperItemImgSwitch, WrapperSwitch, SwatchStyledImg} from "./styles";
import {useField} from "formik";

const SwatchImg = (props) => {
    const {imgSwatch, textImgSwatch, name, value} = props
    const [field] = useField({...props, type: 'checkbox'});
    return <>
        <div>
            <WrapperItemImgSwitch>
                <SwatchStyledImg key="12" isActive={field.checked} imgSwatch={imgSwatch}>
                </SwatchStyledImg>
            </WrapperItemImgSwitch>
            <WrapperSwitch isActive={field.checked}>
                <Switch name={name} value={value} isActive={field.checked} field={field} colorGreen={true}/>
                <p>{textImgSwatch}</p>
            </WrapperSwitch>
        </div>
    </>
}
export default SwatchImg