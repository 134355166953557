import React, {useState} from 'react'
import LabelCart from "../../../components/LabelCart/LabelCart";
import Switch from "../../../components/Switch/Switch";
import Textfield, {StyledTextfield} from "../../../components/TextField/Textfield";
import Button from "../../../components/Button/Button";
import SendIcon from "../../../components/SendIcon/SendIcon";
import {WrapperAction, WrapperConfigFourthMail, WrapperContentFourthMail, WrapperInputs} from "./styles";
import {Error} from "../../../components/Error/Error";
import {useField} from "formik";


const FourthConfigMail = ({isSubmitting, ...props}) => {
    const [field] = useField({name: 'accept', type: 'checkbox'});
    return <>
        <WrapperConfigFourthMail>
            <LabelCart number={'4.'} colorLabel={'#F59CBA'} secondColor={'#EF512C'}/>
            <WrapperContentFourthMail>
                <h1>Uzupełnij dane kontaktowe</h1>
                <p>my skontaktujemy się z Tobą w sprawie wizji lokalnej i oferty</p>
                <WrapperInputs>
                    <Textfield name={"first_name"} fullwidth={true} placeholder={'Imię'}/>
                    <Textfield name={"last_name"} fullwidth={true} placeholder={'Nazwisko'}/>
                    <Textfield name={"email"} fullwidth={true} placeholder={'E-mail'}/>
                    <Textfield name={"phone"} fullwidth={true} placeholder={'Telefon'}/>
                </WrapperInputs>
                <Switch field={field} name={"accept"} align={"center"}
                        label={'Akceptuje that’s what we’re talking about here. A perfect balance between fudgy and cakey so everyone gets what they want out of these brownies. We’ll leave it to you to fight over who gets the crisp edges versus the ooey-gooey middles. Let’s get baking!'}
                        colorGreen={true}/>
                <Error name={"accept"}/>
                <WrapperAction>
                    <Button disabled={isSubmitting} type={"submit"} className='sendBtn' bgColor='pink' color='blue' icon={<SendIcon/>}>Wyślij</Button>
                </WrapperAction>
            </WrapperContentFourthMail>
        </WrapperConfigFourthMail>
    </>
}
export default FourthConfigMail