import styled from "styled-components";

export const WrapperConfigFirst = styled.div`
  background: white;
  padding: 20px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  margin-top: 30px;
  border-radius: 20px;
  text-align: center;
  z-index: 11;
  
  @media (min-width: 1200px) {
    padding: 60px;
    margin-top: 100px;
    max-width: 1110px;
    position: absolute;
    bottom: -263px;
    left: 50%;

    transform: translateX(-50%);
    gap: 20px;
  }
`

export const WrapperItemsImgSwitch = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media (min-width: 624px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }


`