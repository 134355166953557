import styled, {css} from "styled-components";

export const SwatchStyledImg = styled.div`
  width: 204px;
  height: 204px;
  background: url(${props => props.imgSwatch});
  background-size: contain;
  border-radius: 20px;
  padding: 50px 15px 15px 15px;
  color: ${props => props.theme.colors.white};
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  opacity: 0.7;
  border: 0px solid ${props => props.theme.colors.lightOrange};
  ${props => props.isActive && activedTile}

`
export const WrapperItemImgSwitch = styled.div`
  height: 204px;

`

const activedTile = css`
  transform: translateY(-20px);
  box-shadow: 40px 40px 40px 1px rgba(49, 9, 0, 0.3);
  z-index: 100;
  border: 8px solid ${props => props.theme.colors.lightOrange};
  width: 204px;
  height: 204px;
  opacity: 1;
`
export const WrapperSwitch = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70707080;
  ${props => props.isActive && ActiveTitleSwitch}
  p {
    margin-top: 20px;
    font-weight: bold;
  }
`

export const ActiveTitleSwitch = css`
  p {
    color: #333333;
  }
`