import React, {useState} from 'react'
import Switch from "../Switch/Switch";
import styled, {css} from "styled-components";
import {useField} from "formik";

export const WrapperSwatchLabel = styled.div`
  label {
    font-size: 16px;
    font-weight: bold;
    color: #B7B7B7;
  }

  ${props => props.isActive && ActiveTitleSwitch}
`
export const ActiveTitleSwitch = css`
  label {
    color: #333333;;
  }
`
const SwatchLabel = (props) => {
    const {label, name, value} = props
    const [field] = useField({...props, type: 'checkbox'});
    return <>
        <WrapperSwatchLabel isActive={field.checked}>
            <Switch name={name} value={value} label={label} colorGreen={true} field={field}/>
        </WrapperSwatchLabel>
    </>
}
export default SwatchLabel