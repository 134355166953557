import React from 'react'
import LabelCart from "../../../components/LabelCart/LabelCart";
import SwatchImg from "../../../components/SwatchImg/SwatchImg";
import realization1 from '../../../images/realization1.jpg'
import realization2 from '../../../images/realization2.jpg'
import realization3 from '../../../images/realization3.jpg'
import realization4 from '../../../images/slide2.jpg'
import {WrapperConfigFirst, WrapperItemsImgSwitch} from "./styles";
import HeaderCart from "../../../components/HeaderCart/HeaderCart";


const FirstConfig = () => {
    return <>

        <WrapperConfigFirst>
            <LabelCart number={'1.'} colorLabel={'#F59CBA'} secondColor={'#EF512C'}/>
            <HeaderCart>Wybierz rodzaj powierzchni:</HeaderCart>
            <WrapperItemsImgSwitch>
                <SwatchImg name={"type"} value={"0"} imgSwatch={realization1} textImgSwatch={'Relaks'}/>
                <SwatchImg name={"type"} value={"1"} imgSwatch={realization2} textImgSwatch={'Bezpieczeństwo'}/>
                <SwatchImg name={"type"} value={"2"} imgSwatch={realization3} textImgSwatch={'Biuro w domu'}/>
                <SwatchImg name={"type"} value={"3"} imgSwatch={realization4} textImgSwatch={'Eco'}/>
            </WrapperItemsImgSwitch>
        </WrapperConfigFirst>

    </>
}
export default FirstConfig