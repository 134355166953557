import React from 'react'
import {LabelCartStyle, WrapperLabelCart} from "./styles";

const LabelCart = (props) => {
    const {colorLabel, secondColor, number} = props;
    return <>
        <WrapperLabelCart>
            <LabelCartStyle colorLabel={colorLabel} secondColor={secondColor} >
                {number}
            </LabelCartStyle>
        </WrapperLabelCart>

    </>
}
export default LabelCart