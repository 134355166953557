import React from 'react'
import styled from "styled-components";
import {WrapperConfigSecond} from "../SecondConfig/styles";
import LabelCart from "../../../components/LabelCart/LabelCart";
import SwatchLabel from "../../../components/SwatchLabel/SwatchLabel";
import HeaderCart from "../../../components/HeaderCart/HeaderCart";
import {SwitchTextInput} from "./styles";


export const WrapperConfigThird = styled(WrapperConfigSecond)`
  @media (min-width: 1200px) {
    padding: 60px;
  }
`
export const WrapperContentConfigThird = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  gap: 20px;
  @media (min-width: 1200px) {
    margin-top: 60px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
    grid-auto-rows: 30px;
  }

`
const ThirdConfig = () => {
    return <>
        <WrapperConfigThird>
            <LabelCart number={'3.'} colorLabel={'#5BBEDD'} secondColor={'#01804D'}/>
            <HeaderCart>Czym chcesz zarządzać i co chcesz zautomatyzować</HeaderCart>
            <WrapperContentConfigThird>
                <SwatchLabel name={"features"} value={'0'} label={'Oświetlenie'}/>
                <SwatchLabel name={"features"} value={'1'} label={'Sieć domowa Internet/LAN'}/>
                <SwatchLabel name={"features"} value={'2'} label={'System przeciwzalaniowy'}/>
                <SwatchLabel name={"features"} value={'3'} label={'Ogrzewanie'}/>
                <SwatchLabel name={"features"} value={'4'} label={<>Serwer domowy <small>(dane, media, archiwizacja)</small></>}/>
                <SwatchLabel name={"features"} value={'5'} label={'Fotowoltaika /zarzadzanie energią'}/>
                <SwatchLabel name={"features"} value={'6'} label={'System alarmowy /monitoring'}/>
                <SwatchLabel name={"features"} value={'7'} label={<>Urządzenia peryferyjne do pracy <small>(drukarka, monitor, itp.)</small></>}/>
                <SwatchLabel name={"features"} value={'8'} label={'Ogród/system nawadniania'}/>
                <SwatchLabel name={"features"} value={'9'} label={'Dostęp'}/>
                <SwatchLabel name={"features"} value={'10'} label={'Audio/Multiroom'}/>
                <SwatchLabel name={"features"} value={'11'} label={<>Inne/wnętrze <SwitchTextInput name={"features_text[11]"} placeholder={"Wpisz jakie"} /></>}/>
                <SwatchLabel name={"features"} value={'12'} label={<>Zacienianie <small>(żaluzje, zasłony)</small></>}/>
                <SwatchLabel name={"features"} value={'13'} label={<>Klimat <small>(czystość powietrza, rekuperacja, wilgotność)</small></>}/>
                <SwatchLabel name={"features"} value={'14'} label={<>Inne/ogród & zewnętrze: <SwitchTextInput name={"features_text[14]"} placeholder={"Wpisz jakie"} /></>}/>
                <SwatchLabel name={"features"} value={'15'} label={'Kamery/CCTV'}/>
            </WrapperContentConfigThird>
        </WrapperConfigThird>
    </>
}
export default ThirdConfig