import React, {useState} from "react"
import Layout from "../components/Layout/Layout"
import Theme from "../components/Theme/Theme"
import Nav from "../components/Nav/Nav"
import styled from 'styled-components'
import Realizations from "../sections/Realizations"
import DidYouKnow from "../sections/DidYouKnow"
import Footer from "../sections/Footer"
import BannerConfig from "../sections/configurator/BannerConfig/BannerConfig";

import LastBanner from "../sections/configurator/LastBanner/LastBanner";
import SecondConfig from "../sections/configurator/SecondConfig/SecondConfig";
import ThirdConfig from "../sections/configurator/ThirdConfig/ThirdConfig";
import FourthConfigMail from "../sections/configurator/FourthConfigMail/FourthConfigMail";
import {sendForm} from "../services/configurator/form";
import Loader from "../components/Loader/Loader";
import {Formik} from 'formik';


export const ContainerConfig = styled.div`
  max-width: ${props => props.theme.cotainerWidth};
  margin: auto;
  background: #FAF7F5;
  padding-top: 60px;
  padding-bottom: 300px;
  position: relative;
  @media (min-width: 1200px) {
    padding-top: 300px;
    padding-bottom: 240px;
  }
    
`
const ConfiguratorPage = () => {

    const [loading, setLoading] = useState(false);

    /**
     * @returns {Promise<void>}
     * @param data
     */
    const submitForm = async (data) => {
        setLoading(true);

        const res = await sendForm(data);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <Layout>
            <Theme>
                {loading && <Loader/>}
                <Nav/>
                <Formik
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        type: [],
                        features: [],
                        surface: '',
                        rooms: '1',
                        building_type: '',
                        accept: false
                    }}
                    validate={values => {
                        const errors = {};
                        if (!values.email) {
                            errors.email = 'Musisz wpisać adres e-mail';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = 'Niepoprawny format adresu e-mail';
                        }
                        if (!values.accept) {
                            errors.accept = 'Musisz zaznaczyć zgodę';
                        }
                        return errors;
                    }}
                    onSubmit={async (values, {setSubmitting,resetForm}) => {
                        await submitForm(values);
                        setSubmitting(false);
                        resetForm();
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <BannerConfig/>
                            <ContainerConfig>
                                <SecondConfig/>
                                <ThirdConfig/>
                                <FourthConfigMail isSubmitting={isSubmitting}/>
                            </ContainerConfig>
                        </form>
                    )}
                </Formik>
                <LastBanner/>
                <Realizations/>
                <DidYouKnow/>
                <Footer/>
            </Theme>
        </Layout>
    )
}

export default ConfiguratorPage
