import styled from "styled-components";
import {Field} from "formik";

export const SwitchTextInput = styled(Field)`
  border:0;
  border-bottom: 1px solid #b7b7b7;
  font-size: 12px;
  font-weight: 600;
  outline: 0;
  padding-bottom: 7px;
  letter-spacing: 0.025em;
  
  &::placeholder {
    color: #b7b7b7;
    opacity: 1; 
  }

  &:-ms-input-placeholder { 
    color: #b7b7b7;
  }

  &::-ms-input-placeholder { 
    color: #b7b7b7;
  }
`
