import WPAPI from 'wpapi';

export const sendForm = async (formData) => {
    const endpoint = process.env.CONFIGURATOR_API_URL + '/wp-json';
    const site = new WPAPI({
        endpoint: endpoint,
    });
    const req = site.url(endpoint + '/configurator/v1/form');
    return req.create(formData)
}
