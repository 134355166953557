import React from 'react'
import styled from 'styled-components'
import bg from '../../../images/front-background.jpg'

export const LastBannerBg = styled.div`
  min-height: 555px;;
  padding-bottom: 85px;
  background-image:  linear-gradient(to left,transparent 20%,#EF512CD0 20%),url(${bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const LastBanner = () => {
    return (
    <LastBannerBg>

    </LastBannerBg>
    )
}
export default LastBanner