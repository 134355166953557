import React from 'react'


import Button from "../../../components/Button/Button";
import SwitchIcon from "../../../components/SwitchIcon/SwitchIcon";
import FirstConfig from "../FirstConfig/FirstConfig";
import {StartConfigTitle, StyledBanner, WrapperContetnBanner} from "./styles";


const BannerConfig = () => {
    return (
        <StyledBanner>
            <WrapperContetnBanner>
                <p>KONFIGURACJA OFERTY</p>
                <h1>Wybierz pakiet rozwiązań dla siebie</h1>
                <p>my skontaktujemy się z Tobą w sprawie wizji lokalnej i oferty</p>
                <StartConfigTitle>
                    <Button bgColor='lightOrange' icon={<SwitchIcon />}>ROZPOCZNIJ KONFIGURACJĘ TERAZ</Button>
                </StartConfigTitle>
            </WrapperContetnBanner>
            <FirstConfig />
        </StyledBanner>
    )
}
export default BannerConfig
