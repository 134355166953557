
import styled from 'styled-components'

export const LabelCartStyle = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: ${props => props.colorLabel};
    width: 82px;
    height: 58px;
    font-size: 29px;
    color: white;
    font-weight: bold;
    font-family: Apertura, sans-serif;
    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: -24px;
      border-top: 24px solid #EF512C;
      border-top: 24px solid ${props => props.secondColor};;
      border-left: 40px solid transparent;
      left: 0;
    }
  }
`
export const WrapperLabelCart = styled.div`
  display: none;
  @media (min-width: 1200px) {
    position: absolute;
    display: block;
    left: -40px;
    top: 0;
  }
`